export const SET_MITARBEITER = "setMitarbeiter";
export const SET_BESCHWERDEFORM = "setBeschwerdeform";
export const SET_BESCHWERDEKATEGORIE = "setBeschwerdekategorie";
export const SET_STANDORTE = "setStandorte";
export const SET_ABTEILUNGEN = "setAbteilungen";
export const SET_LINIEN = "setLinien";
export const SET_ANREDEN = "setAnreden";
export const SET_HALTESTELLEN = "setHaltestellen";
export const ADD_HALTESTELLE = "addHaltestelle";
export const SET_TICKETARTEN = "setTicketarten";
