import api from "@a/config";

export default {
  getCurrent: () => {
    return api.get("v1/user/current", { withCredentials: true })
  },
  setPassword: (password) => {
    return api.patch("v1/user/current", {password: password}, {withCredentials: true})
  },
  setImmediateTicketReminder: (immediate, daily) => {
    return api.patch("v1/user/tickets", {sofort_ticket: immediate, taegliches_ticket: daily}, {withCredentials: true})
  }
}
