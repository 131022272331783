import {DateTime} from "luxon";

export default {
  normalDate: (val) => {
    if (!val || isNaN(val)) return null;

    const date = new Date(val);
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0) {
      return DateTime.fromMillis(val).toFormat("dd.LL.yyyy");
    } else {
      return DateTime.fromMillis(val).toFormat("dd.LL.yyyy HH:mm");
    }

  }
}
